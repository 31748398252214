<template>
  <div class="div-style">
    <el-descriptions class="margin-top" :column="2" :size="size" border>
      <template slot="extra">
        <el-button type="primary" size="small" @click="backPage"
          >返回</el-button
        >
      </template>
      <el-descriptions-item>
        <template slot="label"> 企业名称 </template>
        {{ details.companyName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 企业信用代码 </template>
        {{ details.companyUnifiedsocialcreditcode }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 通信地址 </template>
        {{ details.companyProvince }} / {{ details.companyCity }} /{{
          details.companyDistrict
        }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 详细地址 </template>
        {{ details.address }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 联系人 </template>
        {{ details.companyContact }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 职务 </template>
        {{ details.companyContactPosition }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 电话/手机 </template>
        {{ details.companyContactPhone }}
      </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label"> 所属行业 </template>
        {{ details.companyIndustry }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 军民融合企业 </template>
        {{ details.companyMilitaryCivilian }}
      </el-descriptions-item>
        <el-descriptions-item>
            <template slot="label">
                文旅行业企业
            </template>
            {{ details.companyCulture }}
        </el-descriptions-item>
        <el-descriptions-item>
            <template slot="label">
                商贸行业企业
            </template>
            {{ details.businessIndustryEnterprises }}
        </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 主要产品/服务阶段 </template>
        {{ details.companyCoreproductStage }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 专利申请量 </template>
        {{ details.companyPatentApplication }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 专利授权量 </template>
        {{ details.companyPatentAuthorization }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 有效专利量 </template>
        {{ details.companyPatentEffective }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 商标拥有数量 </template>
        {{ details.companyBrand }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 软件著作权数量 </template>
        {{ details.companySoftware }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 集成电路布图设计数量 </template>
        {{ details.companyIC }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 拟融资额(万元) </template>
        {{ details.loanAmount }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 期望贷款期限 </template>
        {{ details.loanTimeLimit }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 拟合作银行 </template>
        {{ details.loanBank }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 能够接受的最高融资成本 </template>
        {{ details.loanInterestRate }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 原有融资渠道 </template>
        {{ details.financingChannel }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 融资目的 </template>
        {{ details.loanPurpose }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 是否同意将上述信息提供给金融机构 </template>
        {{ details.loanFinancialInstitution }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 对知识产权质押融资的政策建议 </template>
        {{ details.suggestion }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 申请时间 </template>
        {{ details.createTime }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: "FinancingDetail",
  data() {
    return {
      details: {
        companyName: "",
        companyUnifiedsocialcreditcode: "",
        companyContact: "",
        address: "",
        companyContactPosition: "",
        companyContactPhone: "",
        companyCity: "",
        companyIndustry: "",
        companyMilitaryCivilian: "",
        companyCulture: "",
        companyCoreproductStage: "",
        companyPatentApplication: "",
        companyPatentAuthorization: "",
        companyPatentEffective: "",
        companyBrand: "",
        companySoftware: "",
        companyIC: "",
        loanAmount: "",
        loanTimeLimit: "",
        loanBank: "",
        loanInterestRate: "",
        financingChannel: "",
        loanPurpose: "",
        loanFinancialInstitution: "",
        suggestion: "",
        createTime: "",
      },
      size: "medium",
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    getDetails() {
      this.details = this.$store.state.details;
    },
  },
};
</script>

<style scoped>
.title {
  color: #fff;
  font-size: 15px;
  height: 50px;
  display: flex;
  align-items: center;
  /* text-align: center; */
  justify-content: center;
}

.background-container {
  background-image: linear-gradient(
    90deg,
    rgb(236, 78, 30),
    rgba(252, 174, 31, 0.9),
    rgb(236, 78, 30)
  );

  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.margin-top {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
