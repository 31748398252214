import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import FinancingFormsTwo from "../views/FinancingFormsTwo.vue";
import FnFinancingList from "../views/financing/FnFinancingList.vue";
import FnFinancingDetail from "../views/financing/FnFinancingDetail";
import SysUserList from "../views/sys/SysUserList";
import SysConfig from "../views/sys/SysConfig";
import Success from "../views/Success";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    hidden: true,
  },
  {
    path: "/success",
    name: "Success",
    component: Success,
    hidden: true,
  },
  {
    path: "/form",
    name: "Form",
    component: FinancingFormsTwo,
    hidden: true,
  },
  {
    path: "/home",
    redirect: "/list",
  },

  {
    path: "/home",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/list",
        name: "融资需求列表",
        component: FnFinancingList,
      },
      {
        path: "/detail",
        name: "融资需求详情",
        component: FnFinancingDetail,
      },
      {
        path: "/users",
        name: "用户列表",
        component: SysUserList,
      },
      {
        path: "/config",
        name: "系统配置",
        component: SysConfig,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
