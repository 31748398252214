<template>
  <div class="app-container" style="overflow: auto; overflow-x: hidden">
    <!-- <router-link :to="'/login'" class="link-type">
      <el-button type="warning" plain> 政府管理入口</el-button>
    </router-link> -->

    <!-- 手机 -->
    <div class="bank-title center-div tile-desc" v-show="this.isMobile">
      <router-link :to="'/login'" class="link-type">
        <img
          src="../assets/header.jpg"
          style="margin: auto; display: block; width: 100%"
        />
      </router-link>
    </div>
    <el-card class="box-card-pc" v-show="this.isMobile">
      <img src="../assets/success.png" class="img-mobile" />
      <p style="font-size: 30px; font-weight: bold">提交成功</p>
    </el-card>
    <!-- pc -->
    <div
      class="bank-title center-div tile-desc"
      style="width: 58%; margin: 20px auto"
      v-show="!this.isMobile"
    >
      <router-link :to="'/login'" class="link-type">
        <img src="../assets/header.jpg" style="width: 100%" />
      </router-link>
    </div>
    <el-card
      class="box-card-pc"
      style="margin-top: 10px; width: 58%; margin: 100px auto"
      v-show="!this.isMobile"
    >
      <img src="../assets/success.png" class="img" />
      <p style="font-size: 30px; font-weight: bold">提交成功</p>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Success",
  data() {
    return {
      isMobile: "",
    };
  },

  created() {
    this._isMobile();
  },

  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (flag) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>

<style lang="scss">
.box-card-pc {
  vertical-align: middle;
  text-align: center;
}
.img {
  width: 100px;
  height: 100px;
}
.img-mobile {
  width: 100px;
  height: 100px;
}
.header-img {
  max-width: 100%;
  height: 200px;
  display: block;
  margin: auto;
}

.date-picker {
  .el-input__inner {
    padding: 0px 15px;
  }
}

.el-textarea {
  .el-textarea__inner::-webkit-input-placeholder {
    line-height: 80px;
    text-align: center;
    font-size: 16px;
  }

  /* 使用webkit内核的浏览器 */
  .el-textarea__inner:-moz-placeholder {
    line-height: 80px;
    text-align: center;
    font-size: 16px;
  }

  /* Firefox版本4-18 */
  .el-textarea__inner::-moz-placeholder {
    line-height: 80px;
    text-align: center;
    font-size: 16px;
  }

  /* Firefox版本19+ */
  .el-textarea__inner:-ms-input-placeholder {
    line-height: 80px;
    text-align: center;
    font-size: 16px;
  }
}
</style>
