<template>
    <div class="div-style">
        <!-- 标题 -->
        <div class="el-button-download">
            <el-button type="primary" size="medium" class="el-button-download" :loading="loading" @click="downloadFinancing">下载</el-button>
        </div>
        <el-table :data="tableData" border class="table-style" :default-sort="{prop: 'createTime', order: 'descending'}"
                  @row-dblclick="rowclick">
            <el-table-column type="index" label="序号" align="center">
            </el-table-column>
            <el-table-column prop="companyName" label="企业名称"></el-table-column>
            <el-table-column prop="loanAmount" label="拟融资金额(万元)">
            </el-table-column>
            <el-table-column prop="companyProvince" label="所在省"></el-table-column>
            <el-table-column prop="companyCity" label="所在市"></el-table-column>
            <el-table-column prop="companyDistrict" label="所在区">
            </el-table-column>
            <el-table-column prop="createTime" label="申请时间" sortable></el-table-column>
        </el-table>
        <el-pagination
            background
            layout="prev, pager, next"
            :total=total
            class="el-pagination-style"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
        ></el-pagination>
    </div>
</template>

<script>
import {getBlobRequest} from "../../utils/api";

export default {
    data() {
        return {
            tableData: [],
            currentPage: 1,
            total: 1,
            loading:false
        };
    },
    created() {
        this.getList()
    },

    methods: {
        getList() {
            this.getRequest('/pledge/list?page=' + this.currentPage).then((res) => {
                this.total = res.data.total
                this.tableData = res.data.records
            })

        },
        downloadFinancing() {
            this.loading = true
            this.getBlobRequest("/pledge/list/download").then((res)=>{
                let blob = new Blob([res.data], { type: 'application/xlsx' })
                let url = window.URL.createObjectURL(blob)
                const link = document.createElement('a') // 创建a标签
                link.href = url
                link.download = '知识产权质押融资信息采集表.xlsx' // 重命名文件
                link.click()
                URL.revokeObjectURL(url)
                this.loading = false
            }).catch((err)=>{
                this.$message.error("导出数据失败")
                this.loading = false
            })

        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
        rowclick(row, column, cell, event) {
            this.$store.commit('setDetails', row)
            this.$router.push('/detail')
        }
    }
};
</script>

<style>
.background-container {
    background-image: linear-gradient(
        90deg,
        rgb(236, 78, 30),
        rgba(252, 174, 31, 0.9),
        rgb(236, 78, 30)
    );

    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.title {
    color: #fff;
    font-size: 15px;
    height: 50px;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
}

.table-style {
    margin-left: 20px;
    margin-top: 10px;
    margin-right: 20px;
}

.el-pagination-style {
    margin-left: 10px;
    margin-top: 20px;
}

.el-button-download {
    margin-top: 20px;
    margin-bottom: 10px;
    float: right;
    margin-right: 20px;

}
</style>
