import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from "element-ui";
// import './utils/rem'
import "element-ui/lib/theme-chalk/index.css";
import { postRequest } from "./utils/api";
import { putRequest } from "./utils/api";
import { getRequest } from "./utils/api";
import { deleteRequest } from "./utils/api";
import { getBlobRequest } from "./utils/api";
import { initMenu } from "./utils/menu";

Vue.config.productionTip = false;
Vue.use(Element);

// 以插件的形式使用： this.postRequest
Vue.prototype.postRequest = postRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.getBlobRequest = getBlobRequest;

/**
 * 路由守卫
 * 每次跳转时检测是否有token，如果有token，则加载用户对应的路由列表，存入vuex。
 * 如果本地没有user信息，则请求后台，获取用户信息，用于右上角展示。
 */
router.beforeEach((to, from, next) => {
  if (window.sessionStorage.getItem("tokenStr")) {
    initMenu(router, store);
    next();
  } else {
    if (to.path === "/login") {
      next();
    } else if (to.path === "/form") {
      next();
    } else if (to.path === "/success") {
      next();
    } else {
      next("/form");
    }
  }
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
