import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//导出vuex让其他地方可以使用，这个地方相当于只是声明启用了Vuex
export default new Vuex.Store({
  //公共对象，用来保存所有组件公共的数据
  state:{
    routes:[],
    details:{}
  },
  //mutations，唯一能改变 routes 的方法，但是他是同步执行的
  mutations:{
    initRoutes(state,fmtRoutes){
      //改变 routes
      state.routes = fmtRoutes;
    },
    setDetails(state, tbDetails){
      state.details = tbDetails
    }
  },
  //异步执行的
  actions:{}


})
