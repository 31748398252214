import axios from "axios";
import { Message } from "element-ui";
import router from "../router";

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 如果存在token，请求携带这个token
    if (window.sessionStorage.getItem("tokenStr")) {
      config.headers["Authorization"] =
        window.sessionStorage.getItem("tokenStr");
    }
    return config;
  },
  (error) => {
    console.log(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (success) => {
    if (success.config.responseType === "blob") {
      return success;
    }
    // 业务逻辑错误
    if (success.status && success.status == 200) {
      // 响应信息错误，弹窗提示
      if (
        success.data.code == 500 ||
        success.data.code == 401 ||
        success.data.code == 403
      ) {
        Message.error({
          message: success.data.message,
        });
        return;
      }
      // 响应有信息 除了上面3个状态码code以外都当成请求成功
      // if (success.data.message) {
      //     Message.success({
      //         message: success.data.message
      //     });
      // }
      // 拦截器 对信息进行处理后需要把数据返回，不然下面的响应不能进行
      return success.data;
    }
  },
  (error) => {
    // 连请求响应接口都失败
    if (error.response.code == 504 || error.response.code == 404) {
      Message.error({
        message: "服务器出错！",
      });
    } else if (error.response.code == 403) {
      Message.error({
        message: "权限不足，请联系管理员",
      });
    } else if (error.response.code == 401) {
      Message.error({
        message: "尚未登录，请登录",
      });
      router.replace("/");
    } else {
      // 如果有信息，就打印出来
      if (error.response.data.message) {
        Message.error({
          message: error.response.data.message,
        });
      } else {
        Message.error({
          message: "未知错误！",
        });
      }
    }
    return;
  }
);

let base = "http://demand.jswdmt.com";
// let base = "http://114.55.90.2:5010";

// 传送json格式的post请求
export const postRequest = (url, params) => {
  return axios({
    method: "post",
    url: `${base}${url}`,
    data: params,
  });
};

// 传送json格式的put请求
export const putRequest = (url, params) => {
  return axios({
    method: "put",
    url: `${base}${url}`,
    data: params,
  });
};

// 传送json格式的get请求
export const getRequest = (url, params) => {
  return axios({
    method: "get",
    url: `${base}${url}`,
    data: params,
    // responseType: 'blob'
  });
};

// 传送json格式的delete请求
export const deleteRequest = (url, params) => {
  return axios({
    method: "delete",
    url: `${base}${url}`,
    data: params,
  });
};
export const getBlobRequest = (url, params) => {
  return axios({
    method: "get",
    url: `${base}${url}`,
    data: params,
    responseType: "blob",
  });
};
