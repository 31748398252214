<template>
<!--  <div class="login-container">-->
<!--    <el-form ref="form" :model="form" :rules="rules" label-width="80px" class="login-form" >-->
<!--      <el-form-item>-->
<!--        <h2 class="login-title">-->
<!--          江苏省企业知识产权质押融资需求信息采集表管理-->
<!--        </h2>-->
<!--      </el-form-item>-->
<!--      <el-form-item prop="username">-->
<!--        <el-input-->
<!--          v-model="form.username"-->
<!--          placeholder="请输入用户名"-->
<!--          prefix-icon="el-icon-user"-->
<!--        ></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item prop="password">-->
<!--        <el-input-->
<!--          v-model="form.password"-->
<!--          placeholder="请输入密码"-->
<!--          prefix-icon="el-icon-unlock"-->
<!--          type="password"-->
<!--        ></el-input>-->
<!--      </el-form-item>-->

<!--      <el-form-item>-->
<!--        <el-button type="primary" @click="onSubmit" class="login-btn"-->
<!--          >登录</el-button-->
<!--        >-->
<!--      </el-form-item>-->
<!--      <el-form-item>-->
<!--        <span class="span-style">-->
<!--          相关部门负责人如需查看城市/区县汇总结果请使用分配的账号登录；</span-->
<!--        ></el-form-item-->
<!--      >-->
<!--    </el-form>-->
<!--  </div>-->
    <div class="login-container">
        <el-form ref="form" :model="form" :rules="rules" class="login-form" auto-complete="on" label-position="left">

            <div class="title-container">
                <h4 style="font-size: 20px;color:lightgray;">江苏省企业知识产权质押融资需求信息采集表管理</h4>
            </div>

            <el-form-item prop="username">
        <span class="svg-container">
          <i class="el-icon-user"></i>
        </span>
                <el-input
                    ref="username"
                    v-model="form.username"
                    placeholder="请输入用户名"
                    name="username"
                    type="text"
                    tabindex="1"
                    auto-complete="on"
                />
            </el-form-item>

            <el-form-item prop="password">
        <span class="svg-container">
           <i class="el-icon-unlock"></i>
        </span>
                <el-input
                    ref="password"
                    v-model="form.password"
                    type="password"
                    placeholder="请输入密码"
                    name="password"
                    tabindex="2"
                    auto-complete="on"
                    @keyup.enter.native="handleLogin"
                />
                <!--<span class="show-pwd" @click="showPwd">-->
                <!--<svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />-->
                <!--</span>-->
            </el-form-item>

            <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="onSubmit">登录</el-button>

            <div class="tips">
                <h4>相关部门负责人如需查看城市/区县汇总结果请使用分配的账号登录；</h4>
            </div>
        </el-form>
    </div>

</template>
<script>
export default {
  data() {
    return {
        form: {
        username: '',
        password: '',
        },
        loading: false,
        rules:{
            username: [
                { required: true, message: '请输入用户名', trigger: 'blur' },
            ],
            password: [
                { required: true, message: '请输入密码', trigger: 'blur' },
            ]
        }
    };
  },
  methods: {
    onSubmit() {
        this.loading = true
        this.$refs.form.validate((valid)=>{
            if(!valid) return this.$message.error("用户名/密码为空")
        //    请求后端登录操作
            this.postRequest("/user/login", this.form).then((res)=>{

                if (res) {
                    // 存储用户token
                    const tokenStr = res.data.tokenHead + res.data.token;
                    window.sessionStorage.setItem("tokenStr", tokenStr);
                    // // 跳转首页
                    // this.$router.replace("/home");
                    // 页面跳转
                    let path = this.$route.query.redirect;
                    this.$router.replace(
                        path == "/" || path == undefined ? "/home" : path
                    );
                    this.loading = false
                }
                this.loading = false

            });

        })

    },
  },
};
</script>

<!--<style acoped>-->
<!--/* 背景 */-->
<!--.login-container {-->
<!--  position: relative;-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--  background-color: #2d3a4b;-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--  text-align: center;-->
<!--  justify-content: center;-->
<!--}-->
<!--.login-input {-->
<!--  background-color: #2d3a4b !important;-->
<!--}-->
<!--.el-form-item__content {-->
<!--  color: #2d3a4b !important;-->
<!--}-->

<!--/* 标题 */-->
<!--.login-title {-->
<!--  color: white;-->
<!--  text-align: center;-->
<!--  font-size: 20px;-->
<!--}-->
<!--.span-style {-->
<!--  color: white;-->
<!--}-->
<!--.login-btn {-->
<!--  min-width: 450px;-->
<!--  min-height: 40px;-->
<!--}-->
<!--</style>-->
<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg:#283443;
$light_gray:#fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
    .login-container .el-input input {
        color: $cursor;
    }
}

/* reset element-ui css */
.login-container {
    .el-input {
        display: inline-block;
        height: 47px;
        width: 85%;

        input {
            background: transparent;
            border: 0px;
            -webkit-appearance: none;
            border-radius: 0px;
            padding: 12px 5px 12px 15px;
            color: $light_gray;
            height: 47px;
            caret-color: $cursor;

            &:-webkit-autofill {
                box-shadow: 0 0 0px 1000px $bg inset !important;
                -webkit-text-fill-color: $cursor !important;
            }
        }
    }

    .el-form-item {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        color: #454545;
    }
}
</style>

<style lang="scss" scoped>
$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;

.login-container {
    min-height: 100%;
    width: 100%;
    background-color: $bg;
    overflow: hidden;

    .login-form {
        position: relative;
        width: 520px;
        max-width: 100%;
        padding: 160px 35px 0;
        margin: 0 auto;
        overflow: hidden;
    }

    .tips {
        font-size: 14px;
        color: #fff;
        margin-bottom: 10px;

        span {
            &:first-of-type {
                margin-right: 16px;
            }
        }
    }

    .svg-container {
        padding: 6px 5px 6px 15px;
        color: $dark_gray;
        vertical-align: middle;
        width: 30px;
        display: inline-block;
    }

    .title-container {
        position: relative;

        .title {
            font-size: 26px;
            color: $light_gray;
            margin: 0px auto 40px auto;
            text-align: center;
            font-weight: bold;
        }
    }

    .show-pwd {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 16px;
        color: $dark_gray;
        cursor: pointer;
        user-select: none;
    }
}
</style>

