<template>
  <div class="div-style">
    <!-- 标题 -->
    <div class="el-button-download">
      <el-button
        type="primary"
        size="medium"
        class="el-button-import"
        :loading="loading"
        @click="downloadFinancing"
        >下载</el-button
      >
      <el-button
        type="primary"
        size="medium"
        class="el-button-import"
        @click="importData"
        >导入</el-button
      >
      <el-button
        type="primary"
        size="medium"
        class="el-button-import"
        @click="DeleteData"
        >清空</el-button
      >
    </div>
    <el-table
      :data="tableData"
      border
      class="table-style"
      @row-dblclick="rowclick"
    >
      <el-table-column type="index" label="序号" align="center">
      </el-table-column>
      <el-table-column prop="name" label="名称"> </el-table-column>
      <el-table-column prop="value" label="值"></el-table-column>
      <el-table-column prop="parentId" label="父类ID"></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        sortable
      ></el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      class="el-pagination-style"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="10"
    ></el-pagination>
    <el-dialog title="导入" :visible.sync="dialogImportVisible" width="480px">
      <el-form label-position="right" label-width="170px">
        <el-form-item label="文件">
          <el-upload
            :multiple="false"
            :on-success="onUploadSuccess"
            :action="'http://114.55.90.2:5010/config/importData'"
            :headers="myHeaders"
            class="upload-demo"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传xls文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogImportVisible = false"> 取消 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      total: 1,
      loading: false,
      dialogImportVisible: false, //设置弹框是否弹出
      myHeaders: { Authorization: window.sessionStorage.getItem("tokenStr") },
    };
  },
  created() {
    this.getList();
  },

  methods: {
    getList() {
      this.getRequest("/config/all?page=" + this.currentPage).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    downloadFinancing() {
      this.loading = true;
      this.getBlobRequest("/config/list/download")
        .then((res) => {
          let blob = new Blob([res.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          link.download = "地区配置.xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error("导出数据失败");
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    rowclick(row, column, cell, event) {
      this.$store.commit("setDetails", row);
      this.$router.push("/detail");
    },
    importData() {
      this.dialogImportVisible = true;
    },
    //上传成功调用
    onUploadSuccess() {
      //关闭弹框
      this.dialogImportVisible = false;
      //刷新页面
      this.getList();
    },
    DeleteData() {
      this.getRequest("/config/del").then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.$message.success("数据清空成功");
          this.getList();
        } else {
          this.$message.error("数据清空失败");
        }
      });
    },
  },
};
</script>

<style>
.background-container {
  background-image: linear-gradient(
    90deg,
    rgb(236, 78, 30),
    rgba(252, 174, 31, 0.9),
    rgb(236, 78, 30)
  );

  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.title {
  color: #fff;
  font-size: 15px;
  height: 50px;
  display: flex;
  align-items: center;
  /* text-align: center; */
  justify-content: center;
}

.table-style {
  margin-left: 20px;
  margin-top: 10px;
  margin-right: 20px;
}

.el-pagination-style {
  margin-left: 10px;
  margin-top: 20px;
}

.el-button-download {
  margin-top: 20px;
  margin-bottom: 10px;
  float: right;
  margin-right: 20px;
  margin-left: 10px !important;
}
.el-button-import {
  margin-top: 20px;
  margin-bottom: 10px;
  /*float: right;*/
  margin-right: 20px !important;
}
</style>
