<template>
    <div>
        <!-- 搜索 -->
        <div style="display: flex; justify-content: center; margin-top: 10px">
            <el-input
                v-model="keywords"
                placeholder="通过用户名搜索用户..."
                prefix-icon="el-icon-search"
                style="width: 400px; margin-right: 10px"
            ></el-input>
            <el-button type="primary" icon="el-icon-search" @click="doSearch"
            >搜索</el-button
            >
            <el-button type="primary" icon="el-icon-user" @click="initRolesCity">新增用户</el-button>
        </div>
        <!-- 卡片视图展示主体 -->
        <div class="admin-container">
            <el-card class="admin-card" v-for="(admin, index) in admins" :key="index">
                <div slot="header" class="clearfix">
                    <span>{{ admin.username }}</span>
                    <el-button
                        style="float: right; padding: 3px 0; color: lightskyblue; margin-left: 10px"
                        type="text"
                        icon="el-icon-edit"
                        @click="editHandler(admin)"
                    ></el-button>
                    <el-button
                        style="float: right; padding: 3px 0; color: red"
                        type="text"
                        icon="el-icon-delete"
                        @click="deleteAdmin(admin)"
                    ></el-button>
                </div>
                <!-- 用户信息 -->
                <div class="userInfo-container">
                    <div>用户名:{{ admin.username }}</div>
                    <div>所属省:{{ admin.province }}</div>
                    <div>所属市:{{ admin.city }}</div>
                    <div>所属区:{{ admin.county }}</div>
                    <div>
                        用户状态:
                        <el-switch
                            v-model="admin.enabled"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            active-text="启用"
                            inactive-text="禁用"
                            @change="enabledChange(admin)"
                        >
                        </el-switch>
                    </div>
                    <div >
                        用户角色:
                        <el-tag
                            style="margin-right: 4px"
                            type="success"
                            v-for="(role, indexj) in admin.roles"
                            :key="indexj"
                        >{{ role.nameZh }}</el-tag
                        >
                        <el-popover
                            placement="right"
                            title="角色列表"
                            width="200"
                            trigger="click"
                            @show="showPop(admin)"
                            @hide="hidePop(admin)"
                        >
                            <el-select v-model="selectedRoles" placeholder="请选择">
                                <el-option
                                    v-for="(r, index) in allRoles"
                                    :key="index"
                                    :label="r.nameZh"
                                    :value="r.id"
                                >
                                </el-option>
                            </el-select>
                            <el-button
                                slot="reference"
                                type="text"
                                icon="el-icon-more"
                            ></el-button>

                        </el-popover>
                    </div>
                    <div>创建时间:{{ admin.createTime }}</div>
                </div>
            </el-card>
<!--            注册界面-->
            <el-dialog title="注册" :visible.sync="dialogFormVisible">
                <el-form :model="addUserForm" :rules="rules" ref="form">
                    <el-form-item label="用户名称" :label-width="formLabelWidth" prop="username">
                        <el-input v-model="addUserForm.username"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" :label-width="formLabelWidth" prop="password">
                        <el-input v-model="addUserForm.password" type="password"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" :label-width="formLabelWidth" prop="passwordAgain">
                        <el-input v-model="addUserForm.passwordAgain" type="password"></el-input>
                    </el-form-item>
                    <el-form-item label="角色" :label-width="formLabelWidth">
                        <el-select v-model="addUserForm.selectedRoles" placeholder="请选择">
                            <el-option
                                v-for="(r, index) in allRoles"
                                :key="index"
                                :label="r.nameZh"
                                :value="r.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所在省" :label-width="formLabelWidth">
                        <el-select v-model="addUserForm.companyProvince" placeholder="请选择"
                                   style="margin-right: 20px"
                        >
                            <el-option
                                v-for="item in companyProvince"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所在市" :label-width="formLabelWidth">
                        <el-select @change="cityChange" v-model="addUserForm.companyCity" placeholder="请选择">
                            <el-option
                                v-for="item in companyCity"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所在区" :label-width="formLabelWidth" prop="companyDistrict">
                        <el-select
                            v-model="addUserForm.companyDistrict"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in companyDistrict"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="resetForm('form')">取 消</el-button>
                    <el-button type="primary" @click="addUser">确 定</el-button>
                </div>
            </el-dialog>

            <!--            编辑界面-->
            <el-dialog title="修改用户" :visible.sync="dialogEditVisible">
                <el-form :model="editUserForm" ref="editForm">
                    <el-form-item label="用户名称" :label-width="formLabelWidth">
                        <el-input v-model="editUserForm.username" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="旧密码" :label-width="formLabelWidth">
                        <el-input v-model="editUserForm.password" type="password"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" :label-width="formLabelWidth">
                        <el-input v-model="editUserForm.NewPassword" type="password"></el-input>
                    </el-form-item>
                    <el-form-item label="确认新密码" :label-width="formLabelWidth">
                        <el-input v-model="editUserForm.NewPasswordAgain" type="password"></el-input>
                    </el-form-item>
                    <el-form-item label="角色" :label-width="formLabelWidth">
                        <el-select v-model="editUserForm.selectedRoles" placeholder="请选择">
                            <el-option
                                v-for="(r, index) in allRoles"
                                :key="index"
                                :label="r.nameZh"
                                :value="r.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所在省" :label-width="formLabelWidth">
                        <el-select v-model="editUserForm.province" placeholder="请选择"
                                   style="margin-right: 20px"
                                   clearable
                        >
                            <el-option
                                v-for="item in companyProvince"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所在市" :label-width="formLabelWidth">
                        <el-select @change="cityChange" v-model="editUserForm.city" placeholder="请选择" clearable>
                            <el-option
                                v-for="item in companyCity"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所在区" :label-width="formLabelWidth">
                        <el-select
                            v-model="editUserForm.county"
                            placeholder="请选择"
                            clearable
                        >
                            <el-option
                                v-for="item in companyDistrict"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="resetForm('editForm')">取 消</el-button>
                    <el-button type="primary" @click="updateAdmin">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserList',
    data() {
        return {
            admins: [],
            keywords: '',
            allRoles: [],
            selectedRoles: [],
            visible:false,
            dialogFormVisible: false,
            dialogEditVisible: false,
            addUserForm:{
            },
            editUserForm:{
            },
            formLabelWidth:"120px",
            companyProvince:[
                {id:1, name:"江苏省"}
            ],
            companyDistrict:[],
            companyCity:[],
            rules: {
                username: [
                    {required: true, message: '请输入用户名', trigger: 'blur'},
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {min:6, message: '密码长度最小为6', trigger: 'blur'}
                ],
                passwordAgain: [
                    {required: true, message: '请再次输入密码', trigger: 'blur'},
                    {min:6, message: '密码长度最小为6', trigger: 'blur'}

                ],
            }
        }
    },
    methods: {
        initAdmins() {
            this.getRequest('/user/list/?keywords=' + this.keywords).then(
                (resp) => {
                    if (resp.code === 200) {
                        this.admins = resp.data
                    }
                }
            )
        },
        doSearch() {
            this.initAdmins()
        },
        deleteAdmin(admin) {
            this.$confirm(
                '此操作将永久删除' + admin.username + '操作员, 是否继续?',
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.deleteRequest('/system/user/' + admin.id).then((resp) => {
                        if (resp) {
                            this.initAdmins()
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        updateAdmin(){
            this.putRequest("/system/admin/edit", this.editUserForm).then((res)=>{
                if(res){
                    this.initAdmins()
                }
            })
            this.dialogEditVisible = false

        },
        editHandler(admin){
            this.initAllRoles()
            this.getCity(admin['city'])

            // if(admin['county'] != null){
            //     this.cityChange(admin['city'])
            //
            // }
            this.dialogEditVisible = true
            this.editUserForm =admin
        },

        enabledChange(admin) {
            this.putRequest('/system/admin/', admin).then((resp) => {
                if (resp) {
                    this.initAdmins()
                }
            })
        },
        initAllRoles() {
            this.getRequest('/system/admin/roles').then((resp) => {
                if (resp) {
                    this.allRoles = resp
                }
            })
        },
        showPop(admin) {
            this.initAllRoles()
            let roles = admin.roles
            this.selectedRoles = []
            roles.forEach((r) => {
                this.selectedRoles.push(r.nameZh)
            })
        },
        hidePop(admin) {
            let roles = []
            Object.assign(roles, admin.roles)
            let flag = false
            if(roles.length === 0){
                if(this.selectedRoles){
                    flag = true
                }
            }else {
                if (this.selectedRoles) {
                    if (roles[0].nameZh != this.selectedRoles) {
                        flag = true
                    }
                }
            }
            if (flag) {
                let url = '/system/admin/role?adminId=' + admin.id + '&rids='+this.selectedRoles
                this.putRequest(url).then((resp) => {
                    if (resp) {
                        this.initAdmins()
                    }
                })
            }
        },
        addUser(){
            this.$refs.form.validate((val)=>{
                if(this.addUserForm.password != this.addUserForm.passwordAgain){
                    return this.$message.error("两次密码不一致")
                }
                this.postRequest('/system/admin/add', this.addUserForm).then((rep)=>{
                    if(rep.message === "注册成功！"){
                        this.$refs['form'].resetFields()
                        this.dialogFormVisible = false
                        this.initAdmins()
                    }
                })

            })
        },
        initRolesCity(){
            this.dialogFormVisible = true
            this.initAllRoles()
            this.getCity()
        },
        getCity(value) {
            this.companyCity = []
            this.getRequest("/config/addr?province=江苏省").then((res) => {
                this.companyDistrict = []
                this.companyCity = res.data
                if(value != null){
                    this.cityChange(value)
                }
            }).catch((err) => {
                return this.$message.error("获取所在市失败")
            })
        },
        cityChange(value) {
            this.companyCity.forEach((item) => {
                this.companyDistrict = []
                if (item['name'] === value) {
                    this.getRequest("/config/addr?cityId=" + item["id"]).then((res) => {
                        this.companyDistrict = res.data
                    }).catch((err) => {
                        return this.$message.error("获取区数据失败")
                    })
                }
            })
        },
        resetForm: function (formName) {
            this.$refs[formName].resetFields()
            this.dialogFormVisible = false
            this.dialogEditVisible = false
        }
    },
    mounted() {
        this.initAdmins()
    },
}
</script>

<style>
.admin-card {
    width: 350px;
    margin-bottom: 20px;
}
.admin-container {
    display: flex;
    margin-top: 10px;
    justify-content: space-around;
    flex-wrap: wrap;
}
.enterBtn{
    margin-top: 10px;
}
.userInfo-container {
    font-size: 15px;
    color: #409eff;
}
</style>