import {
    getRequest
} from "./api";
import da from "element-ui/src/locale/lang/da";

export const initMenu = (router, store) => {
    if (store.state.routes.length > 0) {
        return;
    }
    getRequest('/system/menu').then(data => {
        if (data) {
            // 格式化Router
            let fmtRoutes = formatRoutes(data);
            // 添加到router
            router.addRoutes(fmtRoutes);
            // 将数据存入vuex
            store.commit('initRoutes', fmtRoutes);
        }
    })
}


export const formatRoutes = (routes) => {
    let fmtRoutes = [];
    routes.forEach(router => {
        let {
            path,
            component,
            name,
            iconCls,
            children,
        } = router;
        if (children && children instanceof Array) {
            // 递归
            children = formatRoutes(children);
        }
        let fmRouter = {
            path: path,
            name: name,
            iconCls: iconCls,
            children: children,
            component(resolve) {
                if (component.startsWith('Home')) {
                    require(['../views/' + component + '.vue'], resolve);
                }else if(component.startsWith('Sys')){
                    require(['../views/sys/' + component + '.vue'], resolve);

                }else if(component.startsWith('Fn')){
                    require(['../views/financing/' + component + '.vue'], resolve);
                }
            }
        }
        fmtRoutes.push(fmRouter)

    })
    return fmtRoutes;
}