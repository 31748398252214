<template>
    <el-container>
        <el-header class="homeHeader">
<!--            <span class="title">知识产权质押融资信息采集系统-->
<!--            </span>-->
            <div class="title">知识产权质押融资信息采集系统</div>

            <!-- 头像 -->
            <div>
                <el-button type="warning" plain @click="commandHandler">注销</el-button>
            </div>
        </el-header>
        <el-container>
            <el-aside width="200px"><!--菜单点击事件-->
                <!--<el-menu @select="menClick">-->
                <!--开启路由模式之后就不用写传统的点击事件来跳转界面-->
                <el-menu router unique-opened v-for="(item,index) in routes"
                         :index="index + ''"
                         :key="index">
                    <!--遍历路由 routes 中的整个数组所有路由，item 是遍历的每一个路由对象，index是下标-->
                    <!--v-if="user.teacher?!item.Login:item.student"-->
                    <!--true？不显示登录页:除了显示学生页其他的都不显示-->
<!--                    <el-submenu-->
<!--                        v-for="(item,index) in routes"-->
<!--                        :index="index + ''"-->
<!--                        :key="index">-->
<!--                        &lt;!&ndash;菜单导航&ndash;&gt;-->
<!--                        <template slot="title">-->
<!--                            <i :class="item.iconCls" style="color:#1accff; margin-right:5px"></i>-->
<!--                            <span>{{ item.name }}</span>-->
<!--                        </template>-->
                        <!--子菜单-->
                        <!--循环子菜单中的页面对象，children是父菜单中的一个数组 :index="children.path" :页面跳转的路由-->
                        <el-menu-item v-for="(children,childrenIndex) in item.children" :index="children.path"
                                      :key="childrenIndex">
                            {{ children.name }}
                        </el-menu-item>
<!--                    </el-submenu>-->
                </el-menu>
            </el-aside>
            <el-main><!--不是首页的时候在显示面包屑效果-->
                <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <!--当前路由名字-->
                    <el-breadcrumb-item>{{ this.$router.currentRoute.name }}</el-breadcrumb-item>
                </el-breadcrumb>
                <!--                如果路由是/home页面 是首页在显示这个div的内容-->
                <router-view/>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
export default {
    name: "Home",
    data() {
        return {
        }
    },
    computed: {
        routes() {
            return this.$store.state.routes
        },
    },
    methods: {
        commandHandler() {
            this.$confirm('此操作将注销登录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    // 注销登录
                    this.postRequest('/user/logout')
                    // 销毁用户的session
                    window.sessionStorage.removeItem('tokenStr')
                    // 清空菜单
                    this.$store.commit('initRoutes', [])
                    // 跳转到登录页面
                    this.$router.replace('/login')
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作',
                    })
                })
        }
    }
}
</script>

<style acoped>
.title {
    font-size: 30px;
    /*font-family: 华文楷体;*/
    color: white;
}

.homeHeader {
    background-color: orange;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    box-sizing: border-box;
}
</style>

