<template>
  <div class="app-container" style="overflow: auto; overflow-x: hidden">
    <!-- <router-link :to="'/login'" class="link-type">
      <el-button type="warning" plain> 政府管理入口</el-button>
    </router-link> -->

    <div v-show="!this.isMobile" style="width: 58%; margin: 0 auto">
      <router-link :to="'/login'" class="link-type">
        <img src="../assets/header.jpg" alt="/login" style="width: 100%" />
      </router-link>
    </div>
    <div v-show="this.isMobile">
      <router-link :to="'/login'" class="link-type">
        <img
          src="../assets/header.jpg"
          style="margin: auto; display: block; width: 100%"
        />
      </router-link>
    </div>

    <el-form
      ref="formData"
      :model="formData"
      :rules="submitRules"
      style="margin-top: 20px"
      label-position="left"
      label-width="140px"
    >
      <!-- 手机版 -->
      <el-card class="box-card" v-show="this.isMobile">
        <div slot="header" class="clearfix">
          <span style="font-weight: bold">一、企业基本情况</span>
        </div>
        <div class="bottom">
          <el-form-item label="企业名称" :required="true"></el-form-item>
          <el-form-item prop="companyName" label-width="10px">
            <el-input v-model="formData.companyName"></el-input>
          </el-form-item>
          <el-form-item
            label="企业信用代码"
            :required="true"
            label-width="110px"
          ></el-form-item>
          <el-form-item
            label-width="10px"
            prop="companyUnifiedsocialcreditcode"
          >
            <el-input
              v-model="formData.companyUnifiedsocialcreditcode"
            ></el-input>
          </el-form-item>

          <el-form-item label="联系人" :required="true"></el-form-item>
          <el-form-item label-width="10px" prop="companyContact">
            <el-input v-model="formData.companyContact"></el-input>
          </el-form-item>
          <el-form-item label="职务" :required="true"></el-form-item>
          <el-form-item label-width="10px" prop="companyContactPosition">
            <el-input v-model="formData.companyContactPosition"></el-input>
          </el-form-item>

          <el-form-item label="电话/手机" :required="true"></el-form-item>
          <el-form-item label-width="10px" prop="companyContactPhone">
            <el-input v-model="formData.companyContactPhone"></el-input>
          </el-form-item>
          <el-form-item label="通信地址" :required="true"></el-form-item>
          <el-form-item label-width="10px" prop="companyCity">
            <el-row>
              <el-col :span="8" style="margin-right: 10px">
                <el-select
                  v-model="formData.companyProvince"
                  placeholder=""
                  style="width: 100%"
                  disabled
                >
                  <el-option
                    v-for="item in province"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
              <el-col :span="8">
                <el-select
                  v-model="formData.companyCity"
                  placeholder=""
                  style="width: 100%"
                  @change="cityChange"
                >
                  <el-option
                    v-for="item in districtArray"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-select>
              </el-col>
            </el-row>

            <el-row style="margin-top: 10px">
              <el-col :span="8">
                <el-select
                  v-model="formData.companyDistrict"
                  placeholder=""
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in citysArray"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item label="详细地址" :required="true"></el-form-item>
          <el-form-item label-width="10px" prop="address">
            <el-input v-model="formData.address"></el-input>
          </el-form-item>

          <el-form-item label="所属行业" :required="true"></el-form-item>
          <el-form-item label-width="10px" prop="companyIndustry">
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.companyIndustry"
                style="padding: 10px"
              >
                <el-radio
                  v-for="item in company_industrys"
                  :label="item"
                  :key="item"
                  @change="changeInputShow"
                  >{{ item }}
                </el-radio>
                <el-input
                  v-model="inputCompanyIndustry"
                  placeholder="请注明"
                  v-show="inputShowCompanyIndustry"
                  style="
                    position: relative;
                    width: 30%;
                    left: 0;
                    right: 0;
                    top: 0;
                  "
                ></el-input>
              </el-radio-group>
            </div>
          </el-form-item>

          <el-form-item
            label="军民融合企业"
            :required="true"
            label-width="140px"
          ></el-form-item>
          <el-form-item label-width="10px" prop="companyMilitaryCivilian">
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.companyMilitaryCivilian"
                style="margin-left: 10px"
              >
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

          <el-form-item
            label="文旅行业企业"
            label-width="140px"
            :required="true"
          ></el-form-item>
          <el-form-item label-width="10px" prop="companyCulture">
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.companyCulture"
                style="margin-left: 10px"
              >
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

          <el-form-item
            label="商贸行业企业"
            label-width="400px"
            :required="true"
          ></el-form-item>
          <el-form-item prop="businessIndustryEnterprises" label-width="10px">
            <div class="radio_border_div">
              <el-checkbox-group
                v-model="formData.businessIndustryEnterprises"
                :min="0"
                :max="8"
                style="margin: 0px 10px 0px 10px"
              >
                <el-checkbox
                  v-for="item in business_industry_enterprises"
                  :label="item"
                  :key="item"
                  >{{ item }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>

          <el-form-item
            label="主要产品/服务阶段"
            :required="true"
            label-width="140px"
          ></el-form-item>
          <el-form-item label-width="10px" prop="companyCoreproductStage">
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.companyCoreproductStage"
                style="margin: 0px 10px 0px 10px"
              >
                <el-radio
                  v-for="item in company_coreProduct_stages"
                  :label="item"
                  :key="item"
                >
                  {{ item }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </div>
      </el-card>
      <!-- pc -->
      <el-card
        class="box-card"
        style="width: 58%; margin: 20px auto"
        v-show="!this.isMobile"
      >
        <div slot="header" class="clearfix">
          <span style="font-weight: bold">一、企业基本情况</span>
        </div>
        <div class="bottom">
          <el-form-item label="企业名称" prop="companyName" label-width="150px">
            <el-input v-model="formData.companyName"></el-input>
          </el-form-item>
          <el-form-item
            label="企业信用代码"
            prop="companyUnifiedsocialcreditcode"
            label-width="150px"
          >
            <el-input
              v-model="formData.companyUnifiedsocialcreditcode"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="联系人"
            prop="companyContact"
            label-width="150px"
          >
            <el-input v-model="formData.companyContact"></el-input>
          </el-form-item>
          <el-form-item
            label="职务"
            prop="companyContactPosition"
            label-width="150px"
          >
            <el-input v-model="formData.companyContactPosition"></el-input>
          </el-form-item>
          <el-form-item
            label="电话/手机"
            prop="companyContactPhone"
            label-width="150px"
          >
            <el-input v-model="formData.companyContactPhone"></el-input>
          </el-form-item>
          <el-form-item label="通信地址" prop="companyCity" label-width="150px">
            <div>
              <el-row :gutter="24" type="flex" justify="space-between">
                <el-col :span="8">
                  <el-select
                    v-model="formData.companyProvince"
                    placeholder=""
                    disabled
                  >
                    <el-option
                      v-for="item in province"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <el-select
                    v-model="formData.companyCity"
                    placeholder=""
                    @change="cityChange"
                  >
                    <el-option
                      v-for="item in districtArray"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    />
                  </el-select>
                </el-col>
                <el-col :span="5">
                  <el-select v-model="formData.companyDistrict" placeholder="">
                    <el-option
                      v-for="item in citysArray"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    />
                  </el-select>
                </el-col>
              </el-row>
            </div>
          </el-form-item>

          <el-form-item label="详细地址" prop="address" label-width="150px">
            <el-input v-model="formData.address"></el-input>
          </el-form-item>

          <el-form-item
            label="所属行业"
            label-width="150px"
            prop="companyIndustry"
          >
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.companyIndustry"
                style="padding: 10px"
              >
                <el-radio
                  v-for="item in company_industrys"
                  :label="item"
                  :key="item"
                  @change="changeInputShow"
                  >{{ item }}
                </el-radio>
                <el-input
                  v-model="inputCompanyIndustry"
                  placeholder="请注明"
                  v-show="inputShowCompanyIndustry"
                  style="
                    position: relative;
                    width: 200px;
                    left: 0;
                    right: 0;
                    top: 0;
                  "
                ></el-input>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label="军民融合企业"
            label-width="150px"
            prop="companyMilitaryCivilian"
          >
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.companyMilitaryCivilian"
                style="margin-left: 10px"
              >
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label="文旅行业企业"
            label-width="150px"
            prop="companyCulture"
          >
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.companyCulture"
                style="margin-left: 10px"
              >
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label="商贸行业企业"
            prop="businessIndustryEnterprises"
            label-width="150px"
          >
            <div class="radio_border_div">
              <el-checkbox-group
                v-model="formData.businessIndustryEnterprises"
                :min="0"
                :max="8"
                style="margin: 0px 10px 0px 10px"
              >
                <el-checkbox
                  v-for="item in business_industry_enterprises"
                  :label="item"
                  :key="item"
                  >{{ item }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item
            label="主要产品/服务阶段"
            prop="companyCoreproductStage"
            label-width="150px"
          >
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.companyCoreproductStage"
                style="margin: 0px 10px 0px 10px"
              >
                <el-radio
                  v-for="item in company_coreProduct_stages"
                  :label="item"
                  :key="item"
                >
                  {{ item }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </div>
      </el-card>
      <!-- pc -->
      <el-card
        class="box-card"
        style="margin-top: 10px; width: 58%; margin: 20px auto"
        v-show="!this.isMobile"
      >
        <div slot="header" class="clearfix">
          <span style="font-weight: bold"
            >二、企业拥有知识产权情况（上一年度）</span
          >
        </div>
        <div class="bottom">
          <el-row :gutter="24">
            <el-col :span="11">
              <el-form-item
                label="专利申请量"
                label-width="140px"
                prop="companyPatentApplication"
              >
                <el-input v-model="formData.companyPatentApplication">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item
                label="专利授权量"
                label-width="170px"
                prop="companyPatentAuthorization"
              >
                <el-input v-model="formData.companyPatentAuthorization">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :span="11">
              <el-form-item
                label="有效专利量"
                label-width="140px"
                prop="companyPatentEffective"
              >
                <el-input v-model="formData.companyPatentEffective"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item
                label="商标拥有数量"
                label-width="170px"
                prop="companyBrand"
              >
                <el-input v-model="formData.companyBrand"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="11">
              <el-form-item
                label="软件著作权数量"
                label-width="140px"
                prop="companySoftware"
              >
                <el-input v-model="formData.companySoftware"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item
                label="集成电路布图设计数量"
                label-width="170px"
                prop="companyIC"
              >
                <el-input v-model="formData.companyIC"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <!-- mobile -->
      <el-card class="box-card" style="margin-top: 10px" v-show="this.isMobile">
        <div slot="header" class="clearfix">
          <span style="font-weight: bold"
            >二、企业拥有知识产权情况（上一年度）</span
          >
        </div>
        <div class="bottom">
          <el-form-item
            label="专利申请量"
            label-width="100px"
            :required="true"
          ></el-form-item>
          <el-form-item prop="companyPatentApplication" label-width="10px">
            <el-input v-model="formData.companyPatentApplication"> </el-input>
          </el-form-item>
          <el-form-item
            label="专利授权量"
            label-width="100px"
            :required="true"
          ></el-form-item>
          <el-form-item prop="companyPatentAuthorization" label-width="10px">
            <el-input v-model="formData.companyPatentAuthorization"> </el-input>
          </el-form-item>
          <el-form-item
            label="有效专利量"
            label-width="100px"
            :required="true"
          ></el-form-item>
          <el-form-item label-width="10px" prop="companyPatentEffective">
            <el-input v-model="formData.companyPatentEffective"> </el-input>
          </el-form-item>
          <el-form-item
            label="商标拥有数量"
            label-width="130px"
            :required="true"
          ></el-form-item>
          <el-form-item label-width="10px" prop="companyBrand">
            <el-input v-model="formData.companyBrand"> </el-input>
          </el-form-item>
          <el-form-item
            label="软件著作权数量"
            label-width="130px"
            :required="true"
          ></el-form-item>
          <el-form-item label-width="10px" prop="companySoftware">
            <el-input v-model="formData.companySoftware"> </el-input>
          </el-form-item>
          <el-form-item
            label="集成电路布图设计数量"
            label-width="170px"
            :required="true"
          ></el-form-item>
          <el-form-item label-width="10px" prop="companyIC">
            <el-input v-model="formData.companyIC"> </el-input>
          </el-form-item>
        </div>
      </el-card>
      <!-- 手机版 -->
      <el-card class="box-card" style="margin-top: 10px" v-show="this.isMobile">
        <div slot="header" class="clearfix">
          <span style="font-weight: bold">三、融资需求</span>
        </div>
        <div class="bottom">
          <el-form-item
            label="拟融资额(万元)"
            label-width="120px"
            :required="true"
          ></el-form-item>
          <el-form-item prop="loanAmount" label-width="10px">
            <el-input v-model="formData.loanAmount">
              <template slot="suffix">万元</template>
            </el-input>
          </el-form-item>

          <el-form-item
            label="期望贷款期限"
            label-width="170px"
            :required="true"
          ></el-form-item>
          <el-form-item prop="loanTimeLimit" label-width="10px">
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.loanTimeLimit"
                style="margin: 0px 10px 0px 10px"
              >
                <el-radio
                  v-for="item in loan_time_limits"
                  :label="item"
                  :key="item"
                  >{{ item }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label="拟合作银行(可多选)"
            label-width="170px"
            :required="true"
          ></el-form-item>
          <el-form-item prop="loanBank" label-width="10px">
            <!-- <el-input v-model="formData.loanBank"></el-input> -->
            <el-form-item prop="loanBank" label-width="10px">
              <div class="radio_border_div">
                <el-checkbox-group
                  v-model="formData.loanBank"
                  :min="0"
                  :max="8"
                  style="margin: 0px 10px 0px 10px"
                >
                  <el-checkbox
                    v-for="item in loanBank"
                    :label="item"
                    :key="item"
                    @change="changeInputShow"
                    >{{ item }}
                  </el-checkbox>
                  <el-input
                    v-model="inputLoanBank"
                    placeholder="请注明"
                    v-show="inputShowLoanBank"
                    style="
                      position: relative;
                      width: 30%;
                      left: 0;
                      right: 0;
                      top: 0;
                      margin-left: 10px;
                    "
                  ></el-input>
                </el-checkbox-group>
              </div>
            </el-form-item>
          </el-form-item>
          <el-form-item
            label="能够接受的最高融资成本"
            label-width="180px"
            :required="true"
          ></el-form-item>
          <el-form-item prop="loanInterestRate" label-width="10px">
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.loanInterestRate"
                style="margin: 0px 10px 0px 10px"
              >
                <el-radio
                  v-for="item in loan_interest_rates"
                  :label="item"
                  :key="item"
                  >{{ item }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label="原有融资渠道"
            label-width="140px"
            :required="true"
          ></el-form-item>
          <el-form-item prop="financingChannel" label-width="10px">
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.financingChannel"
                style="margin: 0px 10px 0px 10px"
              >
                <el-radio
                  v-for="item in financing_channels"
                  :label="item"
                  :key="item"
                  @change="changeInputShow"
                  >{{ item }}
                </el-radio>
                <el-input
                  v-model="inputFinancingChannel"
                  placeholder="请注明"
                  v-show="inputShowFinancingChannel"
                  style="
                    position: relative;
                    width: 30%;
                    left: 0;
                    right: 0;
                    top: 0;
                    margin-bottom: 5px;
                  "
                ></el-input>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label="融资目的(可多选)"
            label-width="300px"
            :required="true"
          ></el-form-item>
          <el-form-item prop="loanPurpose" label-width="10px">
            <div class="radio_border_div">
              <el-checkbox-group
                v-model="formData.loanPurpose"
                :min="0"
                :max="8"
                style="margin: 0px 10px 0px 10px"
              >
                <el-checkbox
                  v-for="item in loan_purposes"
                  :label="item"
                  :key="item"
                  @change="changeInputShow"
                  >{{ item }}
                </el-checkbox>
                <el-input
                  v-model="inputLoanPurpose"
                  placeholder="请注明"
                  v-show="inputShowLoanPurpose"
                  style="
                    position: relative;
                    width: 30%;
                    left: 0;
                    right: 0;
                    top: 0;
                    margin-left: 10px;
                  "
                ></el-input>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item
            label="是否同意将上述信息提供给金融机构"
            label-width="300px"
            :required="true"
          ></el-form-item>
          <el-form-item label-width="10px" prop="loanFinancialInstitution">
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.loanFinancialInstitution"
                style="margin-left: 10px"
              >
                <el-radio label="愿意">愿意</el-radio>
                <el-radio label="不愿意">不愿意</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </div>
      </el-card>
      <!-- pc版 -->
      <el-card
        class="box-card"
        style="margin-top: 10px; width: 58%; margin: 20px auto"
        v-show="!this.isMobile"
      >
        <div slot="header" class="clearfix">
          <span style="font-weight: bold">三、融资需求</span>
        </div>
        <div class="bottom">
          <el-form-item
            label="拟融资额(万元)"
            prop="loanAmount"
            label-width="270px"
          >
            <el-input v-model="formData.loanAmount">
              <template slot="suffix">万元</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="期望贷款期限"
            prop="loanTimeLimit"
            label-width="270px"
          >
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.loanTimeLimit"
                style="margin: 0px 10px 0px 10px"
              >
                <el-radio
                  v-for="item in loan_time_limits"
                  :label="item"
                  :key="item"
                  >{{ item }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

          <el-form-item
            label="拟合作银行(可多选)"
            label-width="270px"
            prop="loanBank"
          >
            <!-- <el-input v-model="formData.loanBank"></el-input> -->
            <div class="radio_border_div">
              <el-checkbox-group
                v-model="formData.loanBank"
                :min="0"
                :max="8"
                style="padding: 10px"
              >
                <el-checkbox
                  v-for="item in loanBank"
                  :label="item"
                  :key="item"
                  @change="changeInputShow"
                  >{{ item }}
                </el-checkbox>
                <el-input
                  v-model="inputLoanBank"
                  placeholder="请注明"
                  v-show="inputShowLoanBank"
                  style="
                    position: relative;
                    width: 200px;
                    left: 0;
                    right: 0;
                    top: 0;
                    margin-left: 10px;
                    margin-top: 2px;
                  "
                ></el-input>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item
            label="能够接受的最高融资成本"
            label-width="270px"
            prop="loanInterestRate"
          >
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.loanInterestRate"
                style="padding: 10px"
              >
                <el-radio
                  v-for="item in loan_interest_rates"
                  :label="item"
                  :key="item"
                  >{{ item }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label="原有融资渠道"
            label-width="270px"
            prop="financingChannel"
          >
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.financingChannel"
                style="padding: 10px"
              >
                <el-radio
                  v-for="item in financing_channels"
                  :label="item"
                  :key="item"
                  @change="changeInputShow"
                  >{{ item }}
                </el-radio>
                <el-input
                  v-model="inputFinancingChannel"
                  placeholder="请注明"
                  v-show="inputShowFinancingChannel"
                  style="
                    position: relative;
                    width: 200px;
                    left: 0;
                    right: 0;
                    top: 0;
                    margin-top: 2px
                    margin-bottom: 2px;
                  "
                ></el-input>
              </el-radio-group>
            </div>
          </el-form-item>

          <el-form-item
            label="融资目的(可多选)"
            prop="loanPurpose"
            label-width="270px"
          >
            <div class="radio_border_div">
              <el-checkbox-group
                v-model="formData.loanPurpose"
                :min="0"
                :max="8"
                style="padding: 10px"
              >
                <el-checkbox
                  v-for="item in loan_purposes"
                  :label="item"
                  :key="item"
                  @change="changeInputShow"
                  >{{ item }}
                </el-checkbox>
                <el-input
                  v-model="inputLoanPurpose"
                  placeholder="请注明"
                  v-show="inputShowLoanPurpose"
                  style="
                    position: relative;
                    width: 200px;
                    left: 0;
                    right: 0;
                    top: 0;
                    margin-left: 10px;
                    margin-top: 2px;
                  "
                ></el-input>
              </el-checkbox-group>
            </div>
          </el-form-item>

          <el-form-item
            label="是否同意将上述信息提供给金融机构"
            label-width="270px"
            :required="true"
            prop="loanFinancialInstitution"
          >
            <div class="radio_border_div">
              <el-radio-group
                v-model="formData.loanFinancialInstitution"
                style="margin-left: 10px"
              >
                <el-radio label="愿意">愿意</el-radio>
                <el-radio label="不愿意">不愿意</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </div>
      </el-card>

      <!-- 手机版 -->

      <el-card class="box-card" style="margin-top: 10px" v-show="this.isMobile">
        <div slot="header" class="clearfix">
          <span style="font-weight: bold"
            >四、对知识产权质押融资的政策建议</span
          >
        </div>
        <div class="bottom">
          <el-form-item label-width="0px" prop="suggestion">
            <el-input
              type="textarea"
              :rows="4"
              v-model="formData.suggestion"
            ></el-input>
          </el-form-item>
        </div>
      </el-card>
      <!-- pc -->
      <el-card
        class="box-card"
        style="margin-top: 10px; width: 58%; margin: 20px auto"
        v-show="!this.isMobile"
      >
        <div slot="header" class="clearfix">
          <span style="font-weight: bold"
            >四、对知识产权质押融资的政策建议</span
          >
        </div>
        <div class="bottom">
          <el-form-item label-width="0px" prop="suggestion">
            <el-input
              type="textarea"
              :rows="4"
              v-model="formData.suggestion"
            ></el-input>
          </el-form-item>
        </div>
      </el-card>
      <!-- 手机版 -->
      <el-button
        class="bank-title submit-button"
        style="margin-top: 10px"
        @click.native.prevent="submitForm"
        v-show="this.isMobile"
      >
        确认并提交
      </el-button>

      <!-- pc -->
      <el-button
        class="bank-title submit-button"
        style="margin-top: 10px; width: 58%; margin: 20px auto"
        @click.native.prevent="submitForm"
        v-show="!this.isMobile"
      >
        确认并提交
      </el-button>
      
    </el-form>
    <a href="https://beian.miit.gov.cn" style="display: flex;justify-content: center;" target="_blank">苏ICP备18046134号-1</a>
  </div>
</template>

<script>
/* eslint-disable */

import { isSocialCode } from "@/utils/validate";

export default {
  name: "demandForm",
  data() {
    return {
      formData: {
        companyName: "", //企业名称
        companyUnifiedsocialcreditcode: "", //统一社会信用代码
        company_scale: "", //企业规模
        companyContactPhone: "", //企业联系人电话
        address: "", //详细地址
        companyProvince: "江苏省",
        companyCity: "", //所在市
        companyDistrict: "", //所在区
        company_park: "", //企业所在园区
        companyContact: "", //企业联系人
        companyContactPosition: "", //企业联系人职务
        companyIndustry: "", //所属行业
        company_coreProduct_name: "", //主要产品/服务名称
        companyMilitaryCivilian: "", //是否是军民融合企业
        companyCulture: "", //是否是文旅行业企业
        companyCoreproductStage: "", //主要产品/服务阶段
        companyPatentApplication: "", //专利申请量
        companyPatentAuthorization: "", //专利授权量
        companyPatentEffective: "", //有效专利量
        companyBrand: "", //商标拥有数量
        companySoftware: "", //软件著作权数量
        companyIC: "", //集成电路布图设计数量
        loanAmount: "", //拟融资金额
        loanTimeLimit: "", //	期望贷款期限
        loanBank: [], //拟合作银行
        loanInterestRate: "", //能够接受的最高融资成本
        financingChannel: "", //
        loanPurpose: [], //	融资目的
        businessIndustryEnterprises: [], // 商贸行业企业

        loanFinancialInstitution: "", //	是否同意将上述信息提供给金融机构
        suggestion: "", //对知识产权质押融资的政策建议
      },
      inputCompanyIndustry: "",
      inputShowCompanyIndustry: false,
      inputFinancingChannel: "",
      inputShowFinancingChannel: false,
      inputLoanPurpose: "",
      inputLoanBank: "",
      inputShowLoanPurpose: false,
      inputShowLoanBank: false,
      toYear: 2021,
      isMobile: "",
      province: ["江苏省"],
      districtArray: [],
      citysArray: [],
      company_types: ["中外合资企业", "国有企业", "民营企业", "其他"],
      company_scales: ["大型", "中型", "小型", "微型"],
      company_industrys: [
        "电子信息",
        "新材料",
        "光机电一体机",
        "生物制药",
        "新能源与高效节能",
        "高技术服务业",
        "农林牧渔",
        "其他(请注明)",
      ],
      company_coreProduct_stages: ["研制", "试生产", "小批量", "批量", "其他"],
      loan_time_limits: ["3个月以下", "3-6个月", "6个月-1年", "1年-2年"],
      loan_interest_rates: [
        "基准利率",
        "5%-6%",
        "6%-7%",
        "7%-8%",
        "8%-9%",
        "9%-10%",
        "10%以上",
      ],
      financing_channels: [
        "无",
        "银行直接贷款",
        "风投基金",
        "民间借贷",
        "企业拆借",
        "政府资金",
        "其他(请注明)",
      ],
      loan_purposes: [
        "扩大经营规模",
        "补充周转资金",
        "归还到期贷款",
        "固定资产投资",
        "技改项目",
        "投资新项目",
        "创办新企业",
        "其他(请注明)",
      ],
      loanBank: [
        "中国银行",
        "建设银行",
        "省农联社",
        "交通银行",
        "江苏银行",
        "南京银行",
        "苏州银行",
        "浦发银行",
        "邮储银行",
        "招商银行",
        "宁波银行",
        "兴业银行",
        "农业银行",
        "光大银行",
        "杭州银行",
        "中信银行",
        "其他(请注明)",
      ],
      business_industry_enterprises: [
        "零售企业",
        "餐饮企业",
        "老字号企业",
        "否",
      ],

      loan_banks: [
        "中国工商银行",
        "中国银行",
        "招商银行",
        "兴业银行",
        "浦发银行",
        "江苏银行",
        "南京银行",
        "紫金农商银行",
        "其他银行",
      ],
      submitRules: {
        companyName: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入企业名称"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        company_type: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择企业性质"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        companyUnifiedsocialcreditcode: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入信用代码"));
              } else {
                if (!isSocialCode(value)) {
                  callback(new Error("请输入正确的信用代码"));
                } else {
                  callback();
                }
              }
            },
            required: true,
          },
        ],
        company_scale: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择企业规模"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        companyIndustry: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择所属行业"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        companyCity: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择通信地址"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        address: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入详细地址"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        company_park: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入企业所在园区"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        companyContact: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入联系人"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        companyContactPosition: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入职务"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        companyContactPhone: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入电话/手机"));
              } else {
                callback();
              }
            },
            required: true,
          },
          {
            pattern: /^[0-9]*$/,
            message: "请输入数字",
            trigger: ["blur", "change"],
          },
        ],
        company_coreProduct_name: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入主要产品/服务名称"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        companyMilitaryCivilian: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择是否是军民融合企业"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        companyCulture: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择是否是文化企业"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        businessIndustryEnterprises: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择商贸行业企业"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],

        companyCoreproductStage: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择主要产品/服务阶段"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        companyPatentApplication: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入专利申请量"));
              } else {
                callback();
              }
            },
            required: true,
          },
          {
            pattern: /^[0-9]*$/,
            message: "请输入数字",
            trigger: ["blur", "change"],
          },
        ],
        companyPatentAuthorization: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入专利授权量"));
              } else {
                callback();
              }
            },
            required: true,
          },
          {
            pattern: /^[0-9]*$/,
            message: "请输入数字",
            trigger: ["blur", "change"],
          },
        ],
        companyPatentEffective: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入有效专利量"));
              } else {
                callback();
              }
            },
            required: true,
          },
          {
            pattern: /^[0-9]*$/,
            message: "请输入数字",
            trigger: ["blur", "change"],
          },
        ],
        companyBrand: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入商标拥有数量"));
              } else {
                callback();
              }
            },
            required: true,
          },
          {
            pattern: /^[0-9]*$/,
            message: "请输入数字",
            trigger: ["blur", "change"],
          },
        ],
        companySoftware: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入软件著作权数量"));
              } else {
                callback();
              }
            },
            required: true,
          },
          {
            pattern: /^[0-9]*$/,
            message: "请输入数字",
            trigger: ["blur", "change"],
          },
        ],
        companyIC: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入集成电路布图设计数量"));
              } else {
                callback();
              }
            },
            required: true,
          },
          {
            pattern: /^[0-9]*$/,
            message: "请输入数字",
            trigger: ["blur", "change"],
          },
        ],
        loanAmount: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入拟融资金额"));
              } else {
                callback();
              }
            },
            required: true,
          },
          {
            pattern: /^[0-9.]*$/,
            message: "请输入数字",
            trigger: ["blur", "change"],
          },
        ],
        loanTimeLimit: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择期望贷款期限"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        loanBank: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择拟合作银行"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        loanInterestRate: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择能够接受的最高融资成本"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        financingChannel: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择原有融资渠道"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        loanPurpose: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择融资目的"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        loanFinancialInstitution: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请选择是否同意将上述信息提供给金融机构"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
        suggestion: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error("请输入对知识产权质押融资的政策建议"));
              } else {
                callback();
              }
            },
            required: true,
          },
        ],
      },
    };
  },

  created() {
    this._isMobile();
    this.getYear();
    this.getCity();
  },

  methods: {
    getCity() {
      this.districtArray = [];
      this.getRequest("/config/addr?province=" + this.formData.companyProvince)
        .then((res) => {
          this.districtArray = res.data;
        })
        .catch((err) => {
          return this.$message.error("获取所在市失败");
        });
    },
    cityChange(value) {
      this.districtArray.forEach((item) => {
        this.citysArray = [];
        if (item["name"] === value) {
          this.getRequest("/config/addr?cityId=" + item["id"])
            .then((res) => {
              this.citysArray = res.data;
            })
            .catch((err) => {
              return this.$message.error("获取区数据失败");
            });
        }
      });
    },

    getYear() {
      var myDate = new Date();
      this.toYear = myDate.getFullYear() - 1;
    },

    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (flag) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    changeInputShow() {
      if (this.formData.companyIndustry === "其他(请注明)") {
        this.inputShowCompanyIndustry = true;
      } else {
        this.inputShowCompanyIndustry = false;
      }
      if (this.formData.financingChannel === "其他(请注明)") {
        this.inputShowFinancingChannel = true;
      } else {
        this.inputShowFinancingChannel = false;
      }
      if (this.formData.loanPurpose.indexOf("其他(请注明)") > -1) {
        this.inputShowLoanPurpose = true;
      } else {
        this.inputShowLoanPurpose = false;
      }
      if (this.formData.loanBank.indexOf("其他(请注明)") > -1) {
        this.inputShowLoanBank = true;
      } else {
        this.inputShowLoanBank = false;
      }
    },

    submitForm() {
      this.$refs.formData.validate((val) => {
        if (val) {
          this.formData.loanPurpose = String(
            this.formData.loanPurpose.join(",")
          );
          this.formData.loanBank = String(this.formData.loanBank.join(","));
          if (this.formData.companyIndustry === "其他(请注明)") {
            this.formData.companyIndustry = "其他:" + this.inputCompanyIndustry;
          }
          if (this.formData.financingChannel === "其他(请注明)") {
            this.formData.financingChannel =
              "其他:" + this.inputFinancingChannel;
          }
          if (this.formData.loanPurpose.includes("其他(请注明)")) {
            // alert(this.formData.loanPurpose);
            // alert(this.inputLoanPurpose);
            this.formData.loanPurpose = this.formData.loanPurpose.replace(
              "其他(请注明)",
              "其他:" + this.inputLoanPurpose
            );
          }
          if (this.formData.loanBank.includes("其他(请注明)")) {
            // alert(this.formData.loanPurpose);
            // alert(this.inputLoanPurpose);
            this.formData.loanBank = this.formData.loanBank.replace(
              "其他(请注明)",
              this.inputLoanBank
            );
          }
          this.formData.businessIndustryEnterprises = String(
            this.formData.businessIndustryEnterprises.join(",")
          );

          this.postRequest("/pledge/form", this.formData)
            .then((res) => {
              // this.$message.success(res.message);
              // this.$router.go(0);
              this.$router.push("/success");
            })
            .catch((err) => {
              this.$message.error("数据新增失败");
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.tile-desc {
  color: white;
  font-size: 26px;
  line-height: 1.7;
  height: 130px;
}

.center-div {
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.bank-title {
  background: -webkit-linear-gradient(
    left,
    rgba(22, 89, 252),
    rgba(15, 205, 228),
    rgba(22, 89, 252)
  ); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(
    right,
    rgba(22, 89, 252),
    rgba(15, 205, 228),
    rgba(22, 89, 252)
  ); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(
    right,
    rgba(22, 89, 252),
    rgba(15, 205, 228),
    rgba(22, 89, 252)
  ); /* Firefox 3.6 - 15 */
  background: linear-gradient(
    to right,
    rgba(22, 89, 252),
    rgba(15, 205, 228),
    rgba(22, 89, 252)
  ); /* 标准的语法（必须放在最后） */
}

.submit-button {
  width: 100%;
  font-size: 26px;
  color: white;
  line-height: 1.7;
  height: 60px;
  alignment: center;
  display: block;
  margin: 0px auto;
}

.radio_border_div {
  border: 1px solid;
  border-color: #dcdfe6;
  border-width: 1px;
  border-radius: 5px;
}
</style>

<style lang="scss">
.el-radio {
  padding: 5px;
}

.date-picker {
  .el-input__inner {
    padding: 0px 15px;
  }
}

.el-textarea {
  .el-textarea__inner::-webkit-input-placeholder {
    line-height: 80px;
    text-align: center;
    font-size: 16px;
  }

  /* 使用webkit内核的浏览器 */
  .el-textarea__inner:-moz-placeholder {
    line-height: 80px;
    text-align: center;
    font-size: 16px;
  }

  /* Firefox版本4-18 */
  .el-textarea__inner::-moz-placeholder {
    line-height: 80px;
    text-align: center;
    font-size: 16px;
  }

  /* Firefox版本19+ */
  .el-textarea__inner:-ms-input-placeholder {
    line-height: 80px;
    text-align: center;
    font-size: 16px;
  }
}
</style>
