/*
* 统一社会信用代码
* return {Boolean}
* */
export function isSocialCode (str) {
    var numReg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g
    var numRe = new RegExp(numReg)
    if (!numRe.test(str)) {
        return false
    } else {
        return true
    }
}
